import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Link from "gatsby-link";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Globalmarket({data}) {
    return (
        <section className="tekrevol_dark_bg  tekgrip_img_uper  p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={7} lg={7} xl={7} xxl={7} className="my-auto">
                        <div className="example" data-text={data?.global_market_body_txt}>
                            <h2>{checkData(data, 'global_market_title')}</h2>
                        </div>
                        <div>{HtmlParser(checkData(data, 'global_market_description'))}</div>
                        <div className="btn_talk">
                            <Link to={checkData(data, 'global_market_btn_url')}
                                  className="border_btn">{checkData(data, 'global_market_btn_txt')}</Link>
                        </div>
                    </Col>
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="my-auto text-center">
                        <div className="tekgrip_tabs_image">
                            <img
                                src={`${mediaBaseURL}${checkData(data, 'global_market_image')}`}
                                className="img-fluid"
                                alt={checkData(data, 'global_market_title')}/>
                        </div>


                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Globalmarket;